import * as React from 'react';
import PropTypes from 'prop-types';

// bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IoMdClose } from 'react-icons/io';

const ModalExplanation = ({ Whatisit, setWhatisit }) => {
  return (
    <Modal show={Whatisit} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title>Copy & Paste Commands Easily</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '60vh', overflow: 'auto' }}>
        <p>
          Online tool to create and maintain lists of commands. So it's easy to copy them by
          left-clicking.
        </p>
        <p>
          If you're a developer, there's no much to explain. You're gonna find this app useful for
          your daily basis.
        </p>
        <p>
          But if you're not, this application might still be worth it to organize, keep and copy
          info that you use a lot everyday.
        </p>
        <p>
          You can create groups of commands, duplicate groups, create commands to be copied inside
          each group, modify the commands, sort them, etc.
        </p>
        <p>
          If this app helps you, consider helping me keeping it on air, by{' '}
          <strong>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.paypal.com/donate/?hosted_button_id=MMWB2XULZH7LE">
              making a donation
            </a>
          </strong>
          .
        </p>
        <p>
          <strong>Enjoy!</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-danger"
          onClick={() => {
            setWhatisit(false);
          }}>
          Close modal <IoMdClose />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalExplanation.propTypes = {
  Whatisit: PropTypes.bool,
  setWhatisit: PropTypes.func
};

export default ModalExplanation;
