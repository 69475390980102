import React, { useEffect, useState } from 'react';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { BiMessageSquareError } from 'react-icons/bi';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import PropTypes from 'prop-types';

const NotificationAlert = ({ type = 'light', text = '...', visible = false, dateTime }) => {
  const [visibility, setVisibility] = useState(visible);

  const icon = (type) => {
    if (type === 'success') {
      return <IoIosCheckmarkCircleOutline />;
    } else if (type === 'warning') {
      return <BiMessageSquareError />;
    } else {
      return <MdOutlineErrorOutline />;
    }
  };

  useEffect(() => {
    setVisibility(true);

    setTimeout(() => {
      setVisibility(false);
    }, 4000);
  }, [dateTime, text, type, visible]);

  return (
    <div className={`notificationAlert ${visibility ? 'visible' : ''}`}>
      <div className="balloonInside" data-type={type}>
        {icon(type)}

        {text && <p>{text}</p>}
      </div>
    </div>
  );
};

NotificationAlert.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  visible: PropTypes.bool,
  dateTime: PropTypes.string
};

export default NotificationAlert;
