import * as React from 'react';
import { Col } from 'react-bootstrap';
import { MdOutlineDragHandle } from 'react-icons/md';

const DragHandler = (props) => (
  <Col title="Drag up and down to sort" xs={1} {...props} className="col dragHandler">
    <MdOutlineDragHandle />
  </Col>
);

export default DragHandler;
