import * as React from 'react';
import logo from '../assets/img/logo.svg';
import PropTypes from 'prop-types';

const OverlayPresentation = (props) => {
  return (
    <div className={`overlay-presentation presentation ${props.className}`}>
      <div className="imgLogo">
        <img src={logo} alt="Logo" />
      </div>
    </div>
  );
};

OverlayPresentation.propTypes = {
  className: PropTypes.string
};

export default OverlayPresentation;
