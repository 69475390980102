import * as React from 'react';
import CopyCommands from './views/CopyCommands';

const App = () => {
  return (
    <React.Fragment>
      <CopyCommands />
    </React.Fragment>
  );
};

export default App;
