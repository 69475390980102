import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const AuthContext = createContext({
  globaluser: {},
  setglobaluser: () => {}
});

export const AuthProvider = ({ children }) => {
  const [globaluser, setglobaluser] = React.useState({});
  const value = { globaluser, setglobaluser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node
};
