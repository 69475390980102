import * as React from 'react';
import PropTypes from 'prop-types';

// bootstrap
import Button from 'react-bootstrap/Button';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

// icons
import { BsArrowReturnRight } from 'react-icons/bs';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import { CgDuplicate } from 'react-icons/cg';

// useful
import { SortableItem, SortableList } from '@thaddeusjiang/react-sortable-list';
import debounce from '../utils/debounce';

// components
import DragHandler from '../components/DragHandler';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import slugify from '../utils/slugify';
// import { BiCopy } from 'react-icons/bi';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';

const ModalManageCommands = ({
  commands,
  setCommands,
  modalShow,
  setmodalShow,
  currentBlock,
  setCurrentBlock,
  currentBlockList,
  setCurrentBlockList
}) => {
  const [arrayValidation, setArrayValidation] = React.useState(false);
  const placement = 'top';

  let checker = (arr) => arr.every((v) => v.name);

  const addBlockAction = () => {
    const array = currentBlockList;
    setCurrentBlockList([...currentBlockList, { id: `${Number(array.length)}`, name: '' }]);
    // rowSelected[array.length].querySelector('input[type="text"]').focus();

    setTimeout(() => {
      // scroll div down to find element just created + add row button
      const modalBody = document.getElementById('modal-body-scroller');
      modalBody.scrollTo({
        top: modalBody.scrollHeight,
        behavior: 'smooth'
      });

      const rowSelected = document.querySelectorAll('.row-item-command')[array.length];
      rowSelected.querySelectorAll('input[type="text"]')[0].focus();
    }, 300);
  };

  React.useEffect(() => {
    setArrayValidation(checker(currentBlockList));
  }, [currentBlockList]);

  return (
    <Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="w-100 d-block">
        <Modal.Title id="contained-modal-title-vcenter">
          <Form.Control
            size="lg"
            defaultValue={currentBlock.title}
            className="mb-2"
            placeholder={currentBlock.title}
            onChange={debounce((e) => {
              if (e.target.value) {
                setCurrentBlock({
                  ...currentBlock,
                  title: e.target.value
                });
              } else {
                setCurrentBlock({
                  ...currentBlock,
                  title: commands[currentBlock.id].title
                });
              }
            }, 100)}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body id="modal-body-scroller" style={{ maxHeight: '60vh', overflow: 'auto' }}>
        <div className="small">
          <p>
            You can leave the fields as they are to keep current values, or change and save to
            modify.
          </p>
        </div>
        <SortableList items={currentBlockList} setItems={setCurrentBlockList}>
          {({ items }) => (
            <div>
              {items.map((item, index) => (
                <SortableItem
                  className="w-100 row m-0 mb-3 row-item-command"
                  key={item.id}
                  id={item.id}
                  DragHandler={DragHandler}>
                  <Col>
                    <Row className="g-1">
                      <Col>
                        <div className="d-flex" style={{ gap: '5px' }}>
                          <Form.Control
                            type={item.hidden ? 'password' : 'text'}
                            title="Command to be copied"
                            style={{ fontWeight: 'bolder' }}
                            className={`font-code ${item.link ? '--link-style' : ''}`}
                            defaultValue={item.name}
                            placeholder={item.link ? 'https://' : ''}
                            onChange={debounce((e) => {
                              const array = currentBlockList;
                              array[index].name = e.target.value;
                              setCurrentBlockList(array);
                              setArrayValidation(checker(currentBlockList));
                            }, 100)}
                          />
                          <Form.Control
                            style={{ fontSize: '14px' }}
                            title="Hint to understand what the command is"
                            className="font-code w-75"
                            placeholder="Hint for the command"
                            defaultValue={item.hint}
                            onChange={debounce((e) => {
                              const array = currentBlockList;
                              array[index].hint = e.target.value;
                              setCurrentBlockList(array);
                              setArrayValidation(checker(currentBlockList));
                            }, 100)}
                          />
                          <OverlayTrigger
                            key="export"
                            placement={placement}
                            overlay={
                              <Tooltip id={`tooltip-${placement}`}>
                                <span>Click to hide this command (as a password)</span>
                              </Tooltip>
                            }>
                            <div className="pass-check">
                              <Form.Check
                                defaultChecked={item.hidden ? item.hidden : false}
                                disabled={item.link}
                                onChange={(e) => {
                                  const array = currentBlockList;
                                  if (e.target.checked) {
                                    array[index].hidden = true;
                                  } else {
                                    array[index].hidden = false;
                                  }
                                  setCurrentBlockList(array);
                                  setCurrentBlock({ ...currentBlock, timeChange: new Date() });
                                }}
                                type="checkbox"
                                // title="Check if you consider this field as a password"
                                label={item.hidden ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                                id={`eye-for-${slugify(item.name)}-${index}`}
                              />
                            </div>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="export2"
                            placement={placement}
                            overlay={
                              <Tooltip id={`tooltip2-${placement}`}>
                                <span>
                                  Click to turn this command into a <strong>link</strong> ( _blank )
                                  to be opened on another tab
                                </span>
                              </Tooltip>
                            }>
                            <div className="pass-check">
                              <Form.Check
                                defaultChecked={item.link ? item.link : false}
                                onChange={(e) => {
                                  const array = currentBlockList;
                                  if (e.target.checked) {
                                    array[index].link = true;
                                    array[index].hidden = false;
                                  } else {
                                    array[index].link = false;
                                  }
                                  setCurrentBlockList(array);
                                  setCurrentBlock({ ...currentBlock, timeChange: new Date() });
                                }}
                                type="checkbox"
                                // title="Check if you consider this field as a password"
                                label={
                                  item.link ? (
                                    <MdOutlineCheckBox />
                                  ) : (
                                    <MdOutlineCheckBoxOutlineBlank />
                                  )
                                }
                                id={`copy-for-${slugify(item.name)}-${index}`}
                              />
                            </div>
                          </OverlayTrigger>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <Row className="g-1 h-100">
                          <Col xs={6} className="h-100">
                            <Button
                              title={`Duplicate "${item.name}"`}
                              className="w-100 d-flex h-100 align-items-center justify-content-center"
                              variant="outline-primary"
                              onClick={() => {
                                setCurrentBlockList([
                                  ...currentBlockList,
                                  {
                                    name: item.name,
                                    hint: item.hint,
                                    hidden: item.hidden ? true : false,
                                    link: item.link ? true : false,
                                    id: `${Number(currentBlockList.length)}`
                                  }
                                ]);
                              }}>
                              <CgDuplicate className="mx-1" />
                            </Button>
                          </Col>
                          <Col xs={6}>
                            <Button
                              title={`Remove "${item.name}"`}
                              className="w-100 d-flex h-100 align-items-center justify-content-center"
                              variant="outline-danger"
                              onClick={() => {
                                const array = currentBlockList.filter((item, idx) => idx !== index);
                                setCurrentBlockList(array);
                              }}>
                              <IoIosRemoveCircleOutline className="mx-1" />
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </SortableItem>
              ))}
            </div>
          )}
        </SortableList>
        <div className="text-end">
          <Button
            onClick={() => {
              addBlockAction();
            }}
            className="w-100"
            variant="light">
            <BsArrowReturnRight /> Add row <small>(new command)</small>
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!arrayValidation && (
          <small className="text-start text-danger">
            You can't leave any mandatory fields empty.
          </small>
        )}

        <Button
          disabled={!arrayValidation}
          onClick={() => {
            let allCommands = [...commands];

            allCommands[currentBlock.id].list = currentBlockList.map((item) => {
              return {
                command: item.name,
                hint: item.hint,
                hidden: item.hidden ? true : false,
                link: item.link ? true : false
              };
            });
            allCommands[currentBlock.id].title = currentBlock.title;

            setCommands(allCommands);

            setmodalShow(false);
          }}>
          Apply changes and close modal
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalManageCommands.propTypes = {
  commands: PropTypes.array,
  setCommands: PropTypes.func,
  modalShow: PropTypes.bool,
  setmodalShow: PropTypes.func,
  currentBlock: PropTypes.object,
  setCurrentBlock: PropTypes.func,
  currentBlockList: PropTypes.array,
  setCurrentBlockList: PropTypes.func
};

export default ModalManageCommands;
