import * as React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import { AuthContext } from '../contexts/auth';

// bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Col, Row, Spinner } from 'react-bootstrap';

import debounce, { debounceSpeed } from '../utils/debounce';
import validMail from '../utils/validations';
import host from '../utils/host';
import { IoMdClose } from 'react-icons/io';

const ModalLogin = ({ login, setlogin, setloading }) => {
  const { globaluser, setglobaluser } = React.useContext(AuthContext);

  const [loadingLocal, setloadingLocal] = React.useState(false);

  const [signupHandling, setsignupHandling] = React.useState({});
  const [signinHandling, setsigninHandling] = React.useState({});
  // const [pacthHandling, setpacthHandling] = React.useState({});
  const [counter, setCounter] = React.useState();

  const [signup, setSignUp] = React.useState({});
  const [signin, setSignIn] = React.useState({});
  // const [patch] = React.useState({});

  const setSignUpFunc = (key, value) => {
    setSignUp({
      ...signup,
      [key]: value
    });
  };

  const setSignInFunc = (key, value) => {
    setSignIn({
      ...signin,
      [key]: value
    });
  };

  const resetEverything = () => {
    setsignupHandling({
      ...signupHandling,
      error: false
    });
    setsigninHandling({
      ...signinHandling,
      error: false
    });
    setSignUp({});
    setSignIn({});
  };

  const createAccount = async () => {
    setsignupHandling({
      ...signupHandling,
      error: false
    });
    setloadingLocal(true);

    await axios
      .post(`${host}/signup`, signup)
      .then((response) => {
        if (response.data.status === 'error') {
          setsignupHandling({
            ...signupHandling,
            error: true,
            success: false
          });
        } else {
          setsignupHandling({
            ...signupHandling,
            error: false,
            success: true
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setsignupHandling({
          ...signupHandling,
          error: true,
          success: false
        });
      })
      .finally(() => {
        // setTimeout(() => {
        setloadingLocal(false);
        // }, 500)
      });
  };

  const loginAccount = async () => {
    setsigninHandling({
      ...signinHandling,
      error: false
    });
    setloadingLocal(true);

    await axios
      .post(`${host}/signin`, signin)
      .then((response) => {
        if (response.data.status === 'error') {
          setsigninHandling({
            ...signinHandling,
            error: true
          });
          setglobaluser({});
        } else {
          setsigninHandling({
            ...signinHandling,
            error: false
          });
          setloading(true);
          setlogin(false);
          localStorage.setItem('token', response.data.token);
          setglobaluser(response.data.user);
        }
      })
      .catch((error) => {
        console.log(error);
        setsigninHandling({
          ...signinHandling,
          error: true
        });
      })
      .finally(() => {
        // setTimeout(() => {
        setloadingLocal(false);
        // }, 1000)
      });
  };

  const forgotPassword = async (email) => {
    setloadingLocal(true);
    await axios
      .post(`${host}/forgot-password`, { email: email })
      .then((response) => {
        alert(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // setTimeout(() => {
        setloadingLocal(false);
        // }, 500)
      });

    return false;
  };

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <Modal
      show={login}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="relative">
      <Modal.Header>
        <Modal.Title>Copy Commands</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {_.isEmpty(globaluser) ? (
          <React.Fragment>
            <div className="col-sm-6 m-auto text-center mb-5">
              <p>
                <strong>Take your commands with you wherever you go!</strong>
              </p>
              <p>If you have an account, use your credentials to login.</p>
            </div>
            <Row>
              <Col lg={6} className="px-md-5">
                <h4>
                  Sign Up <small className="opacity-50">(create account)</small>
                </h4>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  disabled={signupHandling.success}
                  type="text"
                  className="mb-2"
                  onChange={debounce((e) => {
                    setSignUpFunc('first_name', e.target.value);
                  }, debounceSpeed)}
                />
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  disabled={signupHandling.success}
                  type="text"
                  className="mb-2"
                  onChange={debounce((e) => {
                    setSignUpFunc('last_name', e.target.value);
                  }, debounceSpeed)}
                />
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  disabled={signupHandling.success}
                  type="email"
                  className="mb-2"
                  onChange={debounce((e) => {
                    setSignUpFunc('email', e.target.value);
                  }, debounceSpeed)}
                />
                {signup.email && !validMail(signup.email) && (
                  <p className="my-2 text-danger small">Invalid e-mail format.</p>
                )}
                <Form.Label>Password</Form.Label>
                <Form.Control
                  disabled={signupHandling.success}
                  type="password"
                  className="mb-2"
                  onChange={debounce((e) => {
                    setSignUpFunc('password', e.target.value);
                  }, debounceSpeed)}
                />
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  disabled={signupHandling.success}
                  type="password"
                  onChange={debounce((e) => {
                    setSignUpFunc('password_confirm', e.target.value);
                  }, debounceSpeed)}
                />
                {signup.password &&
                  signup.password_confirm &&
                  signup.password !== signup.password_confirm && (
                    <p className="my-2 text-danger small">Password fields don't match!</p>
                  )}
                <Button
                  disabled={
                    !signup.first_name ||
                    !signup.last_name ||
                    !signup.email ||
                    !validMail(signup.email) ||
                    !signup.password ||
                    signup.password !== signup.password_confirm ||
                    loadingLocal ||
                    signupHandling.success
                  }
                  onClick={() => {
                    createAccount();
                  }}
                  className="w-100 my-3"
                  variant="primary"
                  type="submit">
                  Sign Up
                </Button>
                {signupHandling.error && (
                  <React.Fragment>
                    <p className="text-danger small mb-0">
                      <strong>Error trying to sign up.</strong>
                    </p>
                    <p className="text-danger small mb-0">
                      Make sure all fields are correct and the e-mail is unique.
                    </p>
                  </React.Fragment>
                )}
                {signupHandling.success && (
                  <React.Fragment>
                    <p className="text-primary small mb-0">
                      <strong>Account successfully created.</strong>
                    </p>
                    <p className="small">Use the login form to access the plataform.</p>
                  </React.Fragment>
                )}
              </Col>
              <Col lg={6} className="px-md-5">
                <h4>
                  Sign In <small className="opacity-50">(log in)</small>
                </h4>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="email"
                  className="mb-2"
                  onChange={debounce((e) => {
                    setSignInFunc('email', e.target.value);
                  }, debounceSpeed)}
                  onBlur={debounce((e) => {
                    setSignInFunc('email', e.target.value);
                  }, debounceSpeed)}
                  onMouseOver={debounce((e) => {
                    setSignInFunc('email', e.target.value);
                  }, debounceSpeed)}
                />
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={debounce((e) => {
                    setSignInFunc('password', e.target.value);
                  }, debounceSpeed)}
                  onBlur={debounce((e) => {
                    setSignInFunc('password', e.target.value);
                  }, debounceSpeed)}
                  onMouseOver={debounce((e) => {
                    setSignInFunc('password', e.target.value);
                  }, debounceSpeed)}
                />
                <Button
                  disabled={
                    !signin.email || !validMail(signin.email) || !signin.password || loadingLocal
                  }
                  onClick={() => {
                    loginAccount();
                  }}
                  className="w-100 my-3"
                  variant="primary"
                  type="submit">
                  Sign In
                </Button>
                {signinHandling.error && (
                  <React.Fragment>
                    <p className="text-danger small mb-0">
                      <strong>Error trying to log in.</strong>
                    </p>
                  </React.Fragment>
                )}
                <a
                  // style={{ display: 'none' }}
                  title={`Click to receive a new password by e-mail: ${signin.email}`}
                  onClick={() => {
                    forgotPassword(signin.email);
                  }}
                  className={`${!validMail(signin.email) && 'disabled'} btn btn-light btn-sm my-2`}
                  href="#forgot-password">
                  Reset password
                </a>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="text-center">
              <h5>Logged in as {`${globaluser.first_name} ${globaluser.last_name}`}</h5>
              <p>{globaluser.email}</p>
            </div>
          </React.Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-danger"
          onClick={() => {
            resetEverything();
            setlogin(false);
          }}>
          Close modal <IoMdClose />
        </Button>
      </Modal.Footer>
      {loadingLocal && (
        <div className="spinnerWrapper">
          <Spinner animation="border" variant="light" />
        </div>
      )}
    </Modal>
  );
};

ModalLogin.propTypes = {
  login: PropTypes.bool,
  setlogin: PropTypes.func,
  verifyLogin: PropTypes.func,
  setloading: PropTypes.func
};

export default ModalLogin;
